import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import "./WmobilInstall.css";
import { useHistory } from "react-router-dom";
import { INSTALL_PATH } from "../../constants/paths";
import { useTranslation } from "react-i18next";

const WmobilInstall = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Layout title="Introducción">
      <div className="wmobil-install-background"></div>
      <div className="wmobil-install-main-container">
        <div className="wmobil-install-card">
          <div className="wmobil-install-back-text-container">
            <a
              onClick={() => history.push(INSTALL_PATH)}
              style={{ cursor: "pointer" }}
            >
              ← {t("installPage.backBtn")}
            </a>
          </div>
          <div className="wmobil-install-card-title-container">
            <h2>{t("installPage.wInstall")}</h2>
          </div>
          <div className="wmobil-install-text-container">
            <p>{t("installPage.wSubtitleP1")}</p>
            <p>{t("installPage.wSubtitleP2")}</p>
          </div>
          <div className="wmobil-install-card-empecemos-btn-container">
            <button
              onClick={() =>
                window.open(
                  `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}`,
                  "_blank"
                )
              }
            >
              {t("installPage.wBtn")}
            </button>
          </div>
          <div className="wmobil-install-card-bottom-text-container">
            <p>{t("installPage.wText")}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WmobilInstall;
