import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import "./PaymentReference.css";
import OxxoPayImage from "../../images/oxxopay.png";
import SpeiPayImage from "../../images/spei.png";
import InputMask from "react-input-mask";
import emailjs from "emailjs-com";

const PaymentReference = ({ location }) => {
  const order = location.state.data.order;
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const amount = order.amount;
  const customer = order.customer_info;
  const PaymentReference = order.charges.data[0].payment_method;

  useEffect(() => {
    if (order != undefined) {
      if (PaymentReference.type == "spei") {
        setType("SPEI");
        var templateParams = {
          reply_to: customer.email,
          code: PaymentReference.clabe,
          name: customer.name,
          amount: amount / 100,
        };
        emailjs.send(
          "service_ss9w9ws",
          "template_atspywa",
          templateParams,
          "user_itfQnWMwlnZrVcVMwjZwQ"
        );
      } else {
        setType("OXXO Pay");
        var templateParams = {
          reply_to: customer.email,
          code: PaymentReference.reference,
          name: customer.name,
          amount: amount / 100,
        };
        emailjs.send(
          "service_ss9w9ws",
          "template_tlcxnoe",
          templateParams,
          "user_itfQnWMwlnZrVcVMwjZwQ"
        );
      }
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, []);

  return (
    <Layout title="Referencia de Pago">
      <div className="wmobil-install-background"></div>
      <div className="reference-main-container">
        <div className="reference-card">
          {loading ? (
            <h2>Cargando</h2>
          ) : (
            <>
              <div className="reference-card-title-container">
                <h2>Referencia de pago {type}</h2>
              </div>
              <div className="reference-amount-text-container">
                {type === "SPEI" ? (
                  <img src={SpeiPayImage} />
                ) : (
                  <img src={OxxoPayImage} />
                )}
                <div className="reference-amount-container">
                  <h3>Monto a pagar</h3>
                  <h4>${amount / 100}.00</h4>
                  {type === "SPEI" ? (
                    <span>Transferir exactamente esta cantidad</span>
                  ) : (
                    <span>Oxxo cobrará una comisión adicional.</span>
                  )}
                </div>
              </div>
              <form>
                {type === "SPEI" ? (
                  <div className="reference-card-input-container">
                    <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                      CLABE
                    </span>
                    <div className="reference-card-input">
                      <InputMask
                        value={PaymentReference.clabe}
                        mask="999999999999999999"
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  <div className="reference-card-input-container">
                    <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                      CLABE
                    </span>
                    <div className="reference-card-input">
                      <InputMask
                        value={PaymentReference.reference}
                        mask="9999-9999-9999-99"
                        disabled
                      />
                    </div>
                  </div>
                )}
              </form>
              {type === "SPEI" ? (
                <div className="reference-text-container">
                  <p style={{ fontSize: "1rem" }}>Instrucciones:</p>
                  <p style={{ marginTop: "10px" }}>
                    1.Acceder a tu portal de banco en linea.
                  </p>
                  <p>
                    2.Registrar la CLABE de este recibo para hacer
                    transferencia. El banco necesitará ser STP.
                  </p>
                  <p>
                    3.Hacer la transferencia en linea por la cantidad exacta
                    marcada en este recibo, o la transferencia sera rechazada.
                  </p>
                  <p>
                    4.Para confirmar su pago, el banco en linea le proporcionará
                    un recibo digital. Verificar si se ha hecho correctamente.
                  </p>
                  <p>
                    5.Una vez confirmado el pago, la suscripción será activada.
                  </p>
                  <p style={{ fontSize: "1rem" }}>
                    Se envió un correo a{" "}
                    <a style={{ fontWeight: "bold" }}>{customer.email}</a> con
                    una copia de tu referencia de pago.
                  </p>
                </div>
              ) : (
                <div className="reference-text-container">
                  <p style={{ fontSize: "1rem" }}>Instrucciones:</p>
                  <p style={{ marginTop: "10px" }}>
                    1.Acude a la tienda OXXO más cercana.
                  </p>
                  <p>
                    2.Indica en caja que quieres realizar un pago de OXXO Pay.
                  </p>
                  <p>
                    3.Dicta al cajero el número de referencia en esta ficha para
                    que la tecleé directamente en la pantalla de venta.
                  </p>
                  <p>
                    4.Realiza el pago correspondiente con dinero en efectivo.
                  </p>
                  <p>
                    5.Al confirmar tu pago, el cajero te entregará un
                    comprobante impreso. En él podrás verificar que se haya
                    realizado correctamente. Conserva este comprobante de pago.
                  </p>
                  <p style={{ fontSize: "1rem" }}>
                    Se envió un correo a{" "}
                    <a style={{ fontWeight: "bold" }}>{customer.email}</a> con
                    una copia de tu referencia de pago.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PaymentReference;
