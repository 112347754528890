import React, { useEffect, useRef, useState, useCallback } from "react";
import { getRestaurant, getVentasPorGrupo } from "../../api/Restaurant";
import BackArrow from "../../components/BackArrow";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import Tabs from "../../components/restaurant/Tabs";
import Online from "../../components/restaurant/views/Online";
import PaymentsAndMovements from "../../components/restaurant/views/PaymentsAndMovements";
import Tables from "../../components/restaurant/views/Tables";
import Top from "../../components/restaurant/views/Top";
import { APP_HOME_PATH } from "../../constants/paths";
import { useTranslation } from "react-i18next";

// Icons
import MdCash from "react-ionicons/lib/MdCash";
import MdRepeat from "react-ionicons/lib/MdRepeat";
import MdClose from "react-ionicons/lib/MdClose";
import MdCut from "react-ionicons/lib/MdCut";
import MdPerson from "react-ionicons/lib/MdPerson";
import MdSearch from "react-ionicons/lib/MdSearch";
import MdNutrition from "react-ionicons/lib/MdNutrition";

// Import Modal
import ReportGenerationModal from "../../components/restaurant/ReportGenerationModal";
import DiscountsAndCancellations from "../../components/restaurant/views/DiscountsAndCancellations";
import Goals from "../../components/restaurant/views/Goals";
import SwitchRestaurantName from "./SwitchRestaurantName.js";

/**
 * Retrieves the view parameter from the given location's search string.
 * If the view parameter is not valid, it defaults to "general".
 *
 * @param {Object} location - The location object containing the search string.
 * @param {string} location.search - The search string from the location object.
 * @returns {string} - The valid view parameter or "general" if the view is not valid.
 */
const getViewFromLocation = (location) => {
  const params = new URLSearchParams(location.search);
  const view = params.get("view");
  const validViews = [
    "general",
    "tables",
    "top",
    "payments-and-movements",
    "discounts-and-cancellations",
    "goals",
  ];
  return validViews.includes(view) ? view : "general";
};

const reportOptions = (t) => [
  { type: "sales", icon: MdCash, label: t("restPage.salesRep") },
  { type: "sales-group", icon: MdCash, label: t("restPage.salesRepGroup") },
  { type: "movements", icon: MdRepeat, label: t("restPage.movements") },
  { type: "discounts", icon: MdCut, label: t("restPage.discountRep") },
  {
    type: "cancellations",
    icon: MdClose,
    label: t("restPage.cancellationRep"),
  },
  { type: "assistance", icon: MdPerson, label: t("restPage.attendanceRep") },
  { type: "monitor", icon: MdSearch, label: t("restPage.monitorRep") },
  { type: "products", icon: MdNutrition, label: t("restPage.productsRep") },
];

const RestaurantPage = ({ match, location }) => {
  const { t } = useTranslation();

  // State for restaurant data and UI behavior
  const [restaurant, setRestaurant] = useState({});
  const [groupSales, setGroupSales] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(getViewFromLocation(location));

  // Report modal state
  const [reportType, setReportType] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Report dropdown state
  const [reportsOpen, setReportsOpen] = useState(false);
  const reportsRef = useRef(null);

  /**
   * Fetches restaurant data and updates the state.
   */
  const fetchRestaurantData = useCallback(() => {
    setLoading(true);
    getRestaurant(match.params.id)
      .then(([rest]) => {
        setRestaurant(rest);
        fetchGroupSales(rest.database_string);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [match.params.id]);

  /**
   * Fetches group sales data for a given restaurant database.
   *
   * @param {string} restaurantDB - The database string of the restaurant.
   */
  const fetchGroupSales = (restaurantDB) => {
    getVentasPorGrupo(restaurantDB, new Date())
      .then((res) => setGroupSales(res.grupos))
      .catch(console.error);
  };

  useEffect(() => {
    fetchRestaurantData();
    const interval = setInterval(fetchRestaurantData, 1000 * 60 * 2); // Refresh every 2 minutes
    return () => clearInterval(interval);
  }, [fetchRestaurantData]);

  /**
   * Handles the report type selection and opens the modal.
   *
   * @param {React.MouseEvent} event - The click event.
   */
  const handleReportTypeSelection = (event) => {
    setReportType(event.currentTarget.dataset.type);
    setReportsOpen(false);
    setModalIsOpen(true);
  };

  /**
   * Toggles the visibility of the report dropdown.
   *
   * @param {React.MouseEvent} event - The click event.
   */
  const toggleReportsMenu = (event) => {
    event.preventDefault();
    setReportsOpen((prev) => !prev);
  };

  if (loading) return <LoadingIndicator fullScreen />;
  if (error) {
    return (
      <Layout title="Error">
        <div className="py-8 wrapper">
          <BackArrow to={APP_HOME_PATH} title={t("installPage.backBtn")} />
          <h2 className="text-2xl text-gray-800 font-bold">{error.message}</h2>
        </div>
      </Layout>
    );
  }

  return (
    <>
      <ReportGenerationModal
        closeModal={() => setModalIsOpen(false)}
        modalIsOpen={modalIsOpen}
        reportType={reportType}
        restaurantId={restaurant.id}
      />
      <Layout title={restaurant.text || t("restaurantPage.defaultTitle")}>
        <div className="py-8 wrapper main-admin-page-padding">
          <div className="flex justify-between items-center">
            <BackArrow to={APP_HOME_PATH} title={t("installPage.backBtn")} />
            <ReportDropdown
              reportsOpen={reportsOpen}
              toggleReportsMenu={toggleReportsMenu}
              handleReportTypeSelection={handleReportTypeSelection}
              t={t}
            />
          </div>

          <SwitchRestaurantName
            restaurant={restaurant}
            refreshRestaurant={fetchRestaurantData}
          />
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <hr className="mb-6" />

          {activeTab === "general" && (
            <Online
              online={restaurant.online}
              year={restaurant.year}
              month={restaurant.month}
              week={restaurant.week}
              groupSales={groupSales}
            />
          )}
          {activeTab === "tables" && (
            <Tables {...restaurant} restaurantId={match.params.id} />
          )}
          {activeTab === "top" && (
            <Top
              waiters={restaurant.waiters}
              products={restaurant.products}
              food={restaurant.food}
              drinks={restaurant.drinks}
            />
          )}
          {activeTab === "payments-and-movements" && (
            <PaymentsAndMovements
              payments={restaurant.payments}
              movements={restaurant.movements}
            />
          )}
          {activeTab === "discounts-and-cancellations" && (
            <DiscountsAndCancellations
              cancelledProducts={restaurant.cancelledProducts}
              restaurantId={restaurant.id}
            />
          )}
          {activeTab === "goals" && (
            <Goals
              goals={{
                daily: restaurant.daily_goal,
                weekly: restaurant.weekly_goal,
                monthly: restaurant.monthly_goal,
                yearly: restaurant.yearly_goal,
              }}
              values={{
                daily: parseFloat(restaurant.online.total),
                weekly:
                  parseFloat(restaurant.online.total) +
                  parseFloat(restaurant.week.total),
                monthly:
                  parseFloat(restaurant.online.total) +
                  parseFloat(restaurant.month.total),
                yearly:
                  parseFloat(restaurant.online.total) +
                  parseFloat(restaurant.year.total),
              }}
              restaurantId={restaurant.id}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

/**
 * Renders the report dropdown component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.reportsOpen - Indicates if the report dropdown is open.
 * @param {function} props.toggleReportsMenu - Function to toggle the report dropdown.
 * @param {function} props.handleReportTypeSelection - Function to handle report selection.
 * @param {function} props.t - Translation function.
 */
const ReportDropdown = ({
  reportsOpen,
  toggleReportsMenu,
  handleReportTypeSelection,
  t,
}) => {
  return (
    <div className="relative">
      <button onClick={toggleReportsMenu} className="report-button">
        {t("restPage.repBtn")}
      </button>
      {reportsOpen && (
        <ul
          className="absolute top-0 mt-8 right-0 bg-white border shadow-md rounded text-gray-700 text-sm font-normal z-10"
          style={{ minWidth: "22rem" }}
        >
          {reportOptions(t).map(({ type, icon: Icon, label }) => (
            <li
              key={type}
              className="hover:bg-gray-100 py-2 px-4 flex items-center cursor-pointer"
              onClick={handleReportTypeSelection}
              data-type={type}
            >
              <Icon className="w-6 mr-2" />
              {label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RestaurantPage;
