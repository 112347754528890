import React, { useEffect, useState } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import "./Table.scss";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";

import LoadingIndicator from "../components/LoadingIndicator";
import { changeStatusColor } from "../api/Admin";

const SwitchConnectionButton = ({ license, colorStatus }) => {
  const [id, setId] = useState(license);
  const [color, setColor] = useState(colorStatus);

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  useEffect(() => {
    setId(license);
    setColor(colorStatus);
  }, []);

  // Function to submit form
  const triggerSwitchColor = () => {
    var newColor = "";
    if (color == "#E15241") {
      newColor = "#5BAC43";
    } else {
      newColor = "#E15241";
    }

    changeStatusColor(id, newColor)
      .then((result) => {
        alert("Cambio de color de " + id + " a " + newColor + " exitoso");
      })
      .catch((err) => {});
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#5BAC43",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#E15241",
      boxSizing: "border-box",
    },
  }));

  return (
    <>
      <div
        className="flex"
        style={{
          marginLeft: "25%",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <AntSwitch
            inputProps={{ "aria-label": "ant design" }}
            defaultChecked={color == "#5BAC43" ? true : false}
            onChange={() => {
              triggerSwitchColor();
            }}
          />
        </Stack>
      </div>
    </>
  );
};

export default SwitchConnectionButton;
