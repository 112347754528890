import React from "react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorComponent from "../../ErrorComponent";
import { getReporteCancelaciones } from "../../../api/Restaurant";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  type: yup.string().required("Campo requerido."),
  startDate: yup
    .date()
    .max(new Date(), "La fecha de inicio debe ser antes de hoy.")
    .required("Campo requerido.")
    .typeError("Debes seleccionar una fecha válida."),
  endDate: yup
    .date()
    .min(
      yup.ref("startDate"),
      "La fecha de fin debe ser después de la fecha de inicio"
    )
    .max(new Date(), "La fecha de fin debe ser antes de hoy.")
    .required("Campo requerido.")
    .typeError("Debes seleccionar una fecha válida."),
});

const CancellationsReportForm = ({ restaurantId }) => {
  const { t } = useTranslation();

  // Instantiate form
  const { register, handleSubmit, errors, setError, setValue, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
    });

  // Function to generate report
  const generateReport = ({ type, startDate, endDate }) => {
    const newEndDate = new Date(endDate);
    newEndDate.setDate(endDate.getDate() + 1);

    getReporteCancelaciones(
      restaurantId,
      type,
      startDate.toISOString().slice(0, 19).replace("T", " "),
      newEndDate.toISOString().slice(0, 19).replace("T", " ")
    );
  };

  return (
    <form className="my-4" onSubmit={handleSubmit(generateReport)}>
      <div className="mb-4">
        <label className="block text-gray-700 text-md font-bold mb-3" htmlFor="title">
          {t("restModals.reportDataType")}
        </label>
        <div className="block relative">
          <select
            name="type"
            ref={register}
            className="block appearance-none w-full bg-white border px-2 py-4 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="cuentas_canceladas">{t("restPage.acc")}</option>
            <option value="productos_cancelados">
              {t("dashboard.dropdownOps.topTableDropdown1")}
            </option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <ErrorComponent error={errors.type}></ErrorComponent>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-md font-bold mb-3" htmlFor="title">
          {t("restModals.salesRepModalInput1")}
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
          type="date"
          name="startDate"
          ref={register}
          max={new Date().toISOString().split("T")[0]}
          defaultValue={new Date().toISOString().split("T")[0]}
        />
        <ErrorComponent error={errors.startDate}></ErrorComponent>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-md font-bold mb-3" htmlFor="title">
          {t("restModals.salesRepModalInput2")}
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
          type="date"
          name="endDate"
          ref={register}
          max={new Date().toISOString().split("T")[0]}
          defaultValue={new Date().toISOString().split("T")[0]}
        />{" "}
        <ErrorComponent error={errors.endDate}></ErrorComponent>
      </div>
      <button
        className="w-full bg-brand-700 text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline"
        type="submit"
      >
        {t("restModals.salesRepModalBtn")}
      </button>
    </form>
  );
};

export default CancellationsReportForm;
