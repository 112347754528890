import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorComponent from "../../ErrorComponent";
import { createDistributor } from "../../../api/Admin";
import LoadingIndicator from "../../LoadingIndicator";
import { Redirect } from "react-router-dom";
import { ADMIN_DISTRIBUTORS_PATH } from "../../../constants/adminPaths";

const schema = yup.object().shape({
  name: yup.string().required("Debes ingresar tu nombre."),
  lastName: yup.string().required("Debes ingresar tu apellido."),
  email: yup.string().required("Debes ingresar tu correo electrónico."),
  password: yup.string().required("Debes ingresar tu contraseña"),

  clabe: yup
    .string()
    .required("Debes ingresar la CLABE Interbancaria (18 dígitos)"),
  porcentajeIngresos: yup
    .number()
    .required("Debes ingresar un porcentaje de ingresos"),
});

const NewDistributorForm = () => {
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Instantiate form
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Function to submit form
  const onSubmit = (data) => {
    setLoading(true);

    createDistributor(data)
      .then((result) => {
        setLoading(false);
        setSuccess(true);
        setError(null);
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(false);
        setError(err);
      });
  };

  if (success) return <Redirect to={ADMIN_DISTRIBUTORS_PATH}></Redirect>;
  if (loading) return <LoadingIndicator></LoadingIndicator>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <div className="flex">
        <div className="flex-1 input-wrapper mt-4 mr-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Nombre(s)
          </label>
          <input type="text" name="name" ref={register} className="input" />
          <ErrorComponent error={errors.name}></ErrorComponent>
        </div>

        <div className="flex-1 input-wrapper mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Apellido(s)
          </label>
          <input type="text" name="lastName" ref={register} className="input" />
          <ErrorComponent error={errors.lastName}></ErrorComponent>
        </div>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          Correo electrónico
        </label>
        <input type="email" name="email" ref={register} className="input" />
        <ErrorComponent error={errors.email}></ErrorComponent>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          Contraseña
        </label>
        <input
          type="password"
          name="password"
          ref={register}
          className="input"
        />
        <ErrorComponent error={errors.password}></ErrorComponent>
      </div>

      <div className="flex">
        <div className="flex-1 input-wrapper mt-4 mr-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            CLABE Interbancaria (18 Dígitos)
          </label>
          <input
            type="text"
            name="clabe"
            ref={register}
            className="input"
            minLength="18"
            maxLength="18"
            pattern="[0-9]+"
            placeholder="##################"
          />
          <ErrorComponent error={errors.clabe}></ErrorComponent>
        </div>

        <div className="flex-1 input-wrapper mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Porcentaje a distribuidor de ingresos
          </label>
          <input
            className="text-center"
            type="number"
            name="porcentajeIngresos"
            ref={register}
            min="0"
            max="100"
            placeholder="0"
          />
          {"  %"}
          <ErrorComponent error={errors.porcentajeIngresos}></ErrorComponent>
        </div>
      </div>

      <button type="submit" className="button-main">
        Crear distribuidor
      </button>
      <ErrorComponent error={error}></ErrorComponent>
    </form>
  );
};

export default NewDistributorForm;
