import React, { useEffect } from "react";
import MicroModal from "micromodal";
import "./DistributorDashboardModal.scss";

export const DistributorDashboardModal = ({ open, setOpen }) => {
  MicroModal.init();
  useEffect(() => {
    if (open) {
      MicroModal.show("modal-1");
    }
  }, [open]);

  return (
    <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
      <div className="modal__overlay" tabindex="-1" data-micromodal-close>
        <div
          className="modal__container"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-1-title"
        >
          <header className="modal__header">
            <h2 className="modal__title" id="modal-1-title">
              Wmobil
            </h2>
            <button
              className="modal__close"
              aria-label="Close modal"
              data-micromodal-close
              onClick={() => {
                setOpen(false);
              }}
            ></button>
          </header>
          <main className="modal__content" id="modal-1-content">
            <p>
              Descargar el Servidor Ejecutable de Wmobil. Wmobil se encuentra
              disponible tanto en su <code>Version 64</code> como la{" "}
              <code>Version 32</code>.
            </p>
          </main>
          <footer className="modal__footer">
            <button className="modal__btn modal__btn-primary">Version 64</button>
            <button
              className="modal__btn"
              data-micromodal-close
              aria-label="Close this dialog window"
            >
              Version 32
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default DistributorDashboardModal;
