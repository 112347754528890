import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";

// Import report generation forms
import CancellationsReportForm from "./reports/CancellationsReportForm";
import DiscountsReportForm from "./reports/DiscountsReportForm";
import SalesReportForm from "./reports/SalesReportForm";
import SalesReportFormMovs from "./reports/SalesReportFormMovs";
import AssistanceReportForm from "./reports/AssistanceReportForm";
import InvoicesReportForm from "./reports/InvoicesReportForm";
import CanceledInvoicesReportForm from "./reports/CanceledInvoicesReportForm";
import OrdersMonitorReportForm from "./reports/OrdersMonitorReportForm";
import ProductsReportForm from "./reports/ProductsReportForm";

// Bind modal to the app element
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 54px rgba(0, 0, 0, 0.25)",
    width: "32rem",
  },
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

// Mapping report types to components and titles
const reportConfig = (t) => ({
  sales: {
    title: t("restPage.salesRep"),
    subtitle: t("restModals.salesRepModalSubtitle"),
    Component: (props) => <SalesReportForm {...props} type="corte" />,
  },
  "sales-group": {
    title: t("restPage.salesRepGroup"),
    subtitle: t("restModals.salesRepModalSubtitle"),
    Component: (props) => <SalesReportForm {...props} type="grupo" />,
  },
  movements: {
    title: t("restPage.movements"),
    subtitle: t("restModals.salesRepModalSubtitle2"),
    Component: SalesReportFormMovs,
  },
  discounts: {
    title: t("restPage.discountRep"),
    subtitle: t("restModals.salesRepModalSubtitle3"),
    Component: DiscountsReportForm,
  },
  cancellations: {
    title: t("restPage.cancellationRep"),
    subtitle: t("restModals.salesRepModalSubtitle3"),
    Component: CancellationsReportForm,
  },
  assistance: {
    title: t("restPage.attendanceRep"),
    subtitle: t("restModals.salesRepModalSubtitle4"),
    Component: AssistanceReportForm,
  },
  invoices: {
    title: "Reporte de facturas emitidas por la empresa",
    subtitle:
      "Se generará un archivo PDF con los datos que comienzan desde la fecha de inicio seleccionada hasta la fecha de fin.",
    Component: InvoicesReportForm,
  },
  "inv-cancelations": {
    title: "Reporte de facturas canceladas por la empresa",
    subtitle:
      "Se generará un archivo PDF con los datos que comienzan desde la fecha de inicio seleccionada hasta la fecha de fin.",
    Component: CanceledInvoicesReportForm,
  },
  monitor: {
    title: "Reporte de monitor de ordenes",
    subtitle:
      "Se generará un archivo PDF con los datos que comienzan desde la fecha de inicio seleccionada hasta la fecha de fin.",
    Component: OrdersMonitorReportForm,
  },
  products: {
    title: t("restPage.productsRep"),
    subtitle: t("restModals.salesRepModalSubtitle2"),
    Component: ProductsReportForm,
  },
});

const ReportGenerationModal = ({
  modalIsOpen,
  closeModal,
  reportType,
  restaurantId,
}) => {
  const { t } = useTranslation();
  const config = reportConfig(t)[reportType];

  if (!config) return null;

  const { title, subtitle, Component } = config;

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={title}
      className="py-8 wrapper relative tab-rep-resp"
    >
      <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
        <MdCloseCircle
          className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
          onClick={closeModal}
          role="button"
          aria-label={t("closeModal")}
        />
        <h3 className="font-bold text-gray-900 text-xl mb-1">{title}</h3>
        <p className="text-sm text-gray-700">{subtitle}</p>
        <Component restaurantId={restaurantId} />
      </div>
    </Modal>
  );
};

export default ReportGenerationModal;
