import "jspdf-autotable";
import Chart from "chart.js/auto";

/**
 * Creates a Chart.js chart on a canvas.
 * @param {HTMLCanvasElement} canvas - The canvas element to draw the chart on.
 * @param {Array} folios - The folio numbers.
 * @param {Array} tiempos - The production times.
 * @param {Number} timeLimit - The max time limit.
 * @returns {Promise} - Resolves when chart is fully rendered.
 */
export const renderChart = (canvas, folios, tiempos, timeLimit) => {
  return new Promise((resolve) => {
    const ctx = canvas.getContext("2d");

    // Ensure white background before drawing
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: folios,
        datasets: [
          {
            label: "Tiempo de Producción (min)",
            data: tiempos,
            backgroundColor: "rgba(34, 139, 34, 0.6)",
            borderWidth: 1,
          },
          {
            data: Array(folios.length)
              .fill(null)
              .map((_, i, arr) =>
                i === 0 || i === arr.length - 1 ? timeLimit : null
              ),
            type: "line",
            borderColor: "red",
            borderWidth: 2,
            fill: false,
            spanGaps: true,
            label: "Tiempo limite",
          },
        ],
      },
      plugins: [
        {
          id: "customCanvasBackgroundColor",
          beforeDraw: (chart) => {
            const ctx = chart.ctx;
            // ctx.save();
            // ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            // ctx.restore();
          },
        },
      ],
      options: {
        plugins: {
          legend: { labels: { color: "black" } },
        },
        scales: {
          x: { ticks: { autoSkip: true, maxTicksLimit: 8, color: "black" } },
          y: { beginAtZero: true, ticks: { color: "black" } },
        },
        animation: { onComplete: () => resolve() },
      },
    });
  });
};

/**
 * Converts a chart canvas to a WebP image.
 * @param {HTMLCanvasElement} canvas - The canvas containing the chart.
 * @returns {String} - WebP image data URL.
 */
export const getChartImage = (canvas) => {
  return canvas.toDataURL("image/jpeg", 0.3); // Lower quality for smaller size
};
