import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";

// Import report generation forms
import SalesReportForm from "./reports/SalesReportForm";
import MultipleProductsReportForm from "./reports/MultipleProductsReportForm";

// Bind modal to the app element
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 54px rgba(0, 0, 0, 0.25)",
    width: "32rem",
  },
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const ProductsReportMultipleModal = ({
  modalIsOpen,
  closeModal,
  restaurants,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Reporte de productos"
      className="py-8 wrapper relative tab-rep-resp"
    >
      <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
        <MdCloseCircle
          className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
          onClick={closeModal}
        />
        <h3 className="font-bold text-gray-900 text-xl mb-1">
          {t("restPage.salesRep")}
        </h3>
        <p className="text-sm text-gray-700">
          {t("restModals.salesRepModalSubtitle")}
        </p>
        <MultipleProductsReportForm restaurants={restaurants} />
      </div>
    </Modal>
  );
};

export default ProductsReportMultipleModal;
