import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorComponent from "../../ErrorComponent";
import { useTranslation } from "react-i18next";
import { getReporteProductosById } from "../../../api/Restaurant";
import { LoadingSpinner } from "../../Loading/Spinner";

const schema = yup.object().shape({
  startDate: yup
    .date()
    .max(new Date(), "La fecha de inicio debe ser antes de hoy.")
    .required("Campo requerido.")
    .typeError("Debes seleccionar una fecha válida."),
  endDate: yup
    .date()
    .min(
      yup.ref("startDate"),
      "La fecha de fin debe ser después de la fecha de inicio"
    )
    .max(new Date(), "La fecha de fin debe ser hoy o antes de hoy.")
    .required("Campo requerido.")
    .typeError("Debes seleccionar una fecha válida."),
});

const ProductsReportForm = ({ restaurantId }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const currentDate = new Date();
  const weekBeforeDate = new Date();
  weekBeforeDate.setDate(weekBeforeDate.getDate() - 7);
  const [startDate, setStartDate] = useState(
    weekBeforeDate.toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [hasModifiers, setHasModifiers] = useState(1);
  const [classifications, setClassifications] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Generates a report for the specified date and time range.
   *
   * @param {Object} params - The parameters for generating the report.
   * @param {string} params.startDate - The start date of the report in ISO format.
   * @param {string} params.endDate - The end date of the report in ISO format.
   * @param {number} params.hasModifiers - Whether to include products with modifiers or not.
   * @param {string} params.classifications - Whether to include products with classifications or not.
   * @returns {Promise<void>} A promise that resolves when the report generation is complete.
   */
  const generateReport = async ({
    startDate,
    endDate,
    hasModifiers,
    classifications,
  }) => {
    setIsLoading(true);
    try {
      const parsedStartDate = new Date(startDate);
      const parsedEndDate = new Date(endDate);

      parsedStartDate.setUTCHours(6, 0, 0, 0);
      parsedEndDate.setDate(parsedEndDate.getDate() + 1);
      parsedEndDate.setUTCHours(5, 59, 59, 999);

      await getReporteProductosById(
        restaurantId,
        parsedStartDate.toISOString(),
        parsedEndDate.toISOString(),
        hasModifiers,
        classifications
      );
    } catch (error) {
      console.error("Error generating report:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="my-4" onSubmit={handleSubmit(generateReport)}>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-md font-bold mb-3"
          htmlFor="startDate"
        >
          {t("restModals.salesRepModalInput1")}
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
          type="date"
          name="startDate"
          ref={register}
          max={currentDate.toISOString().split("T")[0]}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <ErrorComponent error={errors.startDate}></ErrorComponent>
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-md font-bold mb-3"
          htmlFor="endDate"
        >
          {t("restModals.salesRepModalInput2")}
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
          type="date"
          name="endDate"
          ref={register}
          max={currentDate.toISOString().split("T")[0]}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <ErrorComponent error={errors.endDate}></ErrorComponent>
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-md font-bold mb-3"
          htmlFor="hasModifiers"
        >
          {t("restModals.productsRepModifiersLabel")}
        </label>
        <div className="block relative">
          <select
            name="hasModifiers"
            ref={register}
            className="block appearance-none w-full bg-white border px-2 py-4 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            value={hasModifiers}
            onChange={(e) => setHasModifiers(Number(e.target.value))}
          >
            <option value={0}>
              {t("restModals.productsRepModifiersOption1")}
            </option>
            <option value={1}>
              {t("restModals.productsRepModifiersOption2")}
            </option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <ErrorComponent error={errors.timeLimit}></ErrorComponent>
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-md font-bold mb-3"
          htmlFor="classifications"
        >
          {t("restModals.productsRepClassificationsLabel")}
        </label>
        <div className="block relative">
          <select
            name="classifications"
            ref={register}
            className="block appearance-none w-full bg-white border px-2 py-4 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            value={classifications}
            onChange={(e) => setClassifications(Number(e.target.value))}
          >
            <option value={0}>
              {t("restModals.productsRepClassificationsOption1")}
            </option>
            <option value={1}>
              {t("restModals.productsRepClassificationsOption2")}
            </option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <ErrorComponent error={errors.timeLimit}></ErrorComponent>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <button
          className="w-full bg-brand-700 text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {t("restModals.salesRepModalBtn")}
        </button>
      )}
    </form>
  );
};

export default ProductsReportForm;
