import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getProfilePicKey, getUserData } from "../../api/User";

import { NavLink, useHistory } from "react-router-dom";
import {
  APP_HOME_PATH,
  APP_MY_FACTURAS_PATH,
  APP_SEARCH_FOLIO_FACTURA,
  APP_WFACTURAS_CLIENTS,
  APP_WFACTURAS_PLANS,
  APP_WFACTURAS_SETTINGS,
} from "../../constants/paths";

import { ADMIN_HOME_PATH } from "../../constants/adminPaths";

import { MdArrowBack } from "react-icons/md";
import MdLogOut from "react-ionicons/lib/MdLogOut";

import { signOut, signIn } from "../../api/Auth";
import { useTranslation } from "react-i18next";
import TimbresComponent from "../TimbresComponent";
import WFacturasVector from "../../images/WFacturasVector.svg";
import { useProfilePic } from "../../context/ProfilePicContext";

const WfacturasLinks = ({ distributor, show, setShow }) => {
  const { t } = useTranslation();
  const { pictureKey, updatePictureKey } = useProfilePic();
  // const { notificationCount } = useContext(NotificationContext);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState({});
  const [adminSessionData, setAdminSessionData] = useState({});
  const [showBurger, setShowBurger] = useState(false);
  const history = useHistory();
  const path = window.location.pathname.split("/")[3];

  //If has admin session
  const adminSession = window.localStorage.getItem("adminSession")
    ? true
    : false;

  useEffect(() => {
    const adminUserSession = JSON.parse(localStorage.getItem("adminSession"));
    if (adminUserSession) {
      setAdminSessionData({
        email: adminUserSession.email,
        password: "123456",
        userEmail: adminUserSession.userEmail,
      });
    } else return;
  }, []);

  const handleRestoreAdminUserSession = () => {
    if (
      window.confirm(
        `Desea restaurar la sesión de ${adminSessionData.email} y terminar la sesión de: ${adminSessionData.userEmail} ?`
      )
    ) {
      removeClickedEvent();
      signOut(adminSessionData.userEmail)
        .then(() => {
          signIn(adminSessionData)
            .then(() => {
              history.push(ADMIN_HOME_PATH);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else return;
  };

  const removeClickedEvent = () => {
    localStorage.removeItem("clicikedFromNavbar");
    localStorage.removeItem("timbresPlanActive");
    localStorage.removeItem("timbresCount");
    localStorage.removeItem("planInterval");
    localStorage.setItem("pictureKey", "");
  };

  // Instantiate form
  const { reset } = useForm({
    defaultValues: userData,
  });

  const handleShowBurger = () => {
    setShowBurger((prevState) => !prevState);
  };

  const profileAvatarUrl =
    pictureKey !== "" && pictureKey
      ? `https://wmobil.nyc3.digitaloceanspaces.com/${pictureKey}`
      : WFacturasVector;

  useEffect(() => {
    if (success) setUserData(getUserData());
  }, [success]);

  useEffect(() => {
    reset(userData);
  }, [userData]);

  //default User
  return (
    <div className="wfacturas-style">
      <div className="wfacturas-logo-container justify-center">
        <img className="wfacturas-logo" src={profileAvatarUrl} />
      </div>
      <input type="checkbox" className="burger-toggle" />
      <div className="menu-button">
        <span className="bg-white w-6 h-1 rounded-full" />
        <span className="bg-white w-6 h-1 rounded-full" />
        <span className="bg-white w-6 h-1 rounded-full" />
      </div>
      <div className="wfacturas-burger">
        {adminSession && (
          <button
            className="wfacturas-button ml-4 mt-4"
            onClick={() => handleRestoreAdminUserSession()}
          >
            {" "}
            <MdArrowBack />
            Back to admin
          </button>
        )}
        <TimbresComponent />
        <NavLink
          to={APP_SEARCH_FOLIO_FACTURA}
          exact
          activeClassName="active"
          className={`mt-8 ${path === "search-factura" ||
              path === "crear-factura-general" ||
              path === "crear-factura"
              ? "wfacturas-active"
              : "wfacturas-items"
            }`}
        >
          <span>Folios pendientes</span>
        </NavLink>
        <NavLink
          to={APP_MY_FACTURAS_PATH}
          exact
          activeClassName="active"
          className={`${path === "mis-facturas" ? "wfacturas-active" : "wfacturas-items"
            }`}
        >
          <span>Facturas realizadas</span>
        </NavLink>
        <NavLink
          to={APP_WFACTURAS_CLIENTS}
          exact
          activeClassName="active"
          className={`${path === "clientes" ? "wfacturas-active" : "wfacturas-items"
            }`}
        >
          <span>Clientes</span>
        </NavLink>
        <NavLink
          to={APP_WFACTURAS_PLANS}
          exact
          activeClassName="active"
          className={`${path === "planes" ? "wfacturas-active" : "wfacturas-items"
            }`}
        >
          <span>Tienda de timbres</span>
        </NavLink>
        <NavLink
          to={APP_WFACTURAS_SETTINGS}
          exact
          activeClassName="active"
          className={`${path === "ajustes" ? "wfacturas-active" : "wfacturas-items"
            }`}
        >
          <span>Configuración</span>
        </NavLink>
        <NavLink
          to={APP_HOME_PATH}
          exact
          activeClassName="active"
          className="wfacturas-items"
          onClick={() => removeClickedEvent()}
        >
          <span>Regresar a WMobil</span>
        </NavLink>
        <NavLink
          to=""
          exact
          activeClassName="active"
          className="wfacturas-items"
          onClick={(e) => {
            e.preventDefault();
            if (
              window.confirm(
                "¿Seguro que deseas cerrar sesión? \nEsto te permitira iniciar sesión en otro navegador"
              )
            )
              signOut(userData.email);
          }}
        >
          <MdLogOut className="fill-current"></MdLogOut>
          <span className="ml-4">{t("navBar.signOut")}</span>
        </NavLink>{" "}
      </div>
    </div>
  );
};

export default WfacturasLinks;
