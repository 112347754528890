import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import "./Install.css";
import { useHistory } from "react-router-dom";
import {
  PERSONAL_INSTALL_PATH,
  COLLABORATOR_INSTALL_PATH,
  WMOBIL_INSTALL_PATH,
} from "../../constants/paths";
import { useTranslation } from "react-i18next";

const Install = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Layout title="Instalación">
      <div className="wmobil-install-background"></div>
      <div className="install-main-container main-admin-page-padding">
        <div className="install-card">
          <div className="install-card-title-container">
            <h2>{t("installPage.title")}</h2>
          </div>
          <div className="install-text-container">
            <p>{t("installPage.subtitle")}</p>
            <p style={{ fontWeight: "bold" }}>{t("installPage.title2")}</p>
          </div>
          <div className="install-card-empecemos-btn-container">
            <button onClick={() => history.push(PERSONAL_INSTALL_PATH)}>
              {t("installPage.btn1")}
            </button>
            <button onClick={() => history.push(COLLABORATOR_INSTALL_PATH)}>
              {t("installPage.btn2")}
            </button>
            <button onClick={() => history.push(WMOBIL_INSTALL_PATH)}>
              {t("installPage.btn3")}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Install;
